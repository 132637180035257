<template>
  <div id="container" v-if="fetch_data">
    <section id="event">
      <div class="wrap">
        <div class="cts_box">
          <h2 class="sub_ttl"><span v-html="queryMarker(htmlEscape(model.title), query)"></span></h2>
          <ul class="class_tag_list">
            <span class="tag class01" v-if="model.rank_regular_plus">本科プラス</span>
            <span class="tag class02" v-if="model.rank_regular">本科</span>
            <span class="tag class03" v-if="model.rank_preparatory">予科</span>
            <span class="tag class04" v-if="model.rank_general">一般</span>
            <span class="tag class05" v-if="model.rank_student">学生科</span>
            <span class="tag class06" v-if="false /*model.rank_corporation*/">法人科</span>
          </ul>
          <div class="event_data">
            <p class="date"><span class="day">{{ moment(model.open_date, 'YYYY/MM/DD (ddd)') }}</span><span
                class="time">{{ model.open_time }}</span></p>
            <p class="lead_txt"></p>
            <p v-html="queryMarker(model.description, query)"></p>

            <div v-if="model.category === 'category_camp_tour' || model.category === 'category_camp_camp'">
              <div class="detail flex" v-if="model.offline"><span class="tag">開催地</span>{{ model.place }}</div>
              <div class="detail flex" v-if="model.online"><span class="tag red">LIVE</span>オンライン</div>
            </div>
            <div v-else>
              <div class="detail flex" v-if="model.offline"><span class="tag">教室</span>{{ model.place }}</div>
              <div class="detail flex" v-if="model.online || model.zoom_url"><span class="tag red">LIVE</span>オンライン
              </div>
            </div>

            <div v-if="model.joined && model.live_active">
              <p>
                こちらのボタンを押してZoomアプリを展開してください。Zoomアプリのインストールをされていない方は、画面に表示される指示に従ってZoomアプリのインストールをお願いします。
              </p>
              <div class="btn_wrap event_cv">
                <a v-bind:href="model.zoom_url" v-bind:class="{disabled: !model.live_active}" target="_blank"
                   class="submit light h_auto">視聴を開始する<span>（Zoomアプリが開きます）</span></a>
              </div>
            </div>

            <div class="cts_box" v-if="model.join_contents_active && model.video_tag && model.joined">
              <h3 class="min_ttl">研修会参加者限定コンテンツ</h3>
              <div class="special_cts">
                <h4>研修会参加者限定動画</h4>
                <p class="red">{{ moment(model.join_closed_at, 'YYYY/MM/DD (ddd) HH:mm') }} まで視聴可能です。</p>
                <p class="lead_txt" v-html="model.movie_text"></p>
                <div class="mov_wrap" v-if="model.video_tag">
                  <div class="youtube vimeo" style="padding: 0; height: auto;" v-html="model.video_tag"></div>
                </div>
              </div>
            </div>

            <div class="cts_box" v-if="model.join_contents_active && !model.video_tag && model.joined">
              <h3 class="min_ttl">研修会参加者限定コンテンツ</h3>
              <div class="special_cts">
                <h4>研修会参加者限定動画</h4>
                <p class="red">近日公開予定</p>
                <p class="lead_txt" v-html="model.movie_text"></p>
                <div class="event_mov_wrap">
                  <div class="coming_soon"><img src="/common/images/coming_soon.jpg" alt=""></div>
                </div>
              </div>
            </div>

            <!-- 暫定 抽選研修会なら申し込み関連を出さない -->
            <template v-if="!isLottery()">
              <div class="btn_wrap event_cv" v-if="!model.request_enabled">
                <p class="caution" v-if="model.rank_regular_plus_request_enabled">
                  現在「本科プラス」塾生の優先申し込み期間です。<br>
                </p>
                <template v-if="model.request_start">

                  <p class="caution">
                    <template v-if="current_user.sns_rank === 'rank_regular_plus'">
                      本科プラス先行受付開始は以下の日時となります。
                    </template>
                    <template v-else>
                      通常受付開始は下記の日時となります。
                    </template>
                  </p>
                  <a href="" class="submit light disabled">申し込み受付前です</a>
                  <p class="limit">受付開始日：{{ moment(model.request_start, 'YYYY/MM/DD (ddd) HH:mm') }}</p>
                </template>
              </div>
              <div class="btn_wrap event_cv" v-if="model.request_enabled">
                <!-- 受付中 -->
                <p v-if="!model.joined">参加のお申込みはこちらから</p>
                <p class="caution" v-if="model.rank_regular_plus_request_enabled">
                  現在「本科プラス」塾生の優先申し込み期間です。
                </p>

                <a href="javascript:void(0)"
                   v-bind:class="{disabled: model.join_contents_close || model.close || model.joined}"
                   @click="joinClicked"
                   class="submit basic arrow">この研修会に参加する
                </a>
                <p class="limit">参加申込期限：{{ moment(model.close_date, 'YYYY/MM/DD (ddd) HH:mm') }} まで</p>
                <p class="limit" v-if="model.joined">申し込み済みです。</p>
                <p class="limit" v-else-if="model.join_contents_close || model.close">申し込み受付は終了いたしました。</p>
              </div>

              <div class="btn_wrap event_cv" v-else-if="model.lottery_enabled">
                <!-- 抽選中 -->
                <p v-if="!model.joined">参加のお申込みはこちらから</p>
                <p class="caution" v-if="model.rank_regular_plus_request_enabled">
                  現在「本科プラス」塾生の優先申し込み期間です。
                </p>

                <p class="caution">
                  {{ model.lottery_lead_text }}
                </p>

                <a href="javascript:void(0)"
                   v-bind:class="{disabled: model.join_contents_close || model.close || model.joined}"
                   @click="joinClicked"
                   class="submit basic arrow">この研修会の抽選に申し込む
                </a>
                <p class="limit">参加申込期限：{{ moment(model.close_date, 'YYYY/MM/DD (ddd) HH:mm') }} まで</p>
                <p class="limit" v-if="model.joined">申し込み済みです。</p>
                <p class="limit" v-else-if="model.join_contents_close || model.close">申し込み受付は終了いたしました。</p>
              </div>

            </template>
          </div>
        </div>

        <div class="cts_box" v-if="model.pdf_file_url && model.joined">
          <div class="mov_archves_list bdr_none">
            <h3 class="min_ttl">教材ダウンロード</h3>
            <div class="mov_archives flex">
              <a v-bind:href="model.pdf_file_url" class="flex" target="_blank">
                <div class="thumb"><img src="/common/images/thumb_pdf.png" alt=""></div>
                <div class="info" v-html="model.pdf_text"></div>
              </a>
            </div>
          </div>
        </div>

        <div class="cts_box" v-if="false">
          <h3 class="min_ttl">研修会概要</h3>
          <div class="df_table">
            <dl class="flex">
              <dt>開催日</dt>
              <dd>{{ moment(model.open_date, 'YYYY/MM/DD (ddd)') }} {{ model.open_time }}</dd>
            </dl>
            <dl class="flex">
              <dt>参加定員</dt>
              <dd>
                <div v-if="model.offline">{{ model.sales_count }}名（現地）</div>
                <div v-if="model.online && model.sales_count_online">{{ model.sales_count_online }}名（オンライン）</div>
                <span class="red">※定員になり次第、締め切りとさせていただきます。</span>
              </dd>
            </dl>
            <dl class="flex">
              <dt>参加資格</dt>
              <dd>
                <span v-if="model.join_rank_mode === 1">複眼経済塾塾生の方</span>
                <span v-if="model.join_rank_mode === 2">一般の方</span>
                <span v-if="model.join_rank_mode === 3">複眼経済塾塾生または一般の方</span>
              </dd>
            </dl>
            <dl class="flex">
              <dt>申込期限</dt>
              <dd>{{ moment(model.close_date, 'YYYY/MM/DD (ddd) HH:mm') }}</dd>
            </dl>
            <dl class="flex" v-if="model.offline">
              <dt>価格（現地）</dt>
              <dd>
                <div class="price"
                     v-bind:class="{'active': current_user && current_user.sns_rank === 'rank_regular_plus'}">
                  <span class="class">本科プラス：</span>
                  <span v-if="model.rank_regular_plus">¥{{ delimited(model.price_rank_regular_plus) }}</span>
                  <span v-else>参加できません</span>
                </div>
                <div class="price" v-if="!isRegularDefault()"
                     v-bind:class="{'active': current_user && current_user.sns_rank === 'rank_regular'}">
                  <span class="class">本科：</span>
                  <span v-if="model.rank_regular">¥{{ delimited(model.price_rank_regular) }}</span>
                  <span v-else>参加できません</span>
                </div>
                <div class="price active" v-if="isRegularDefault()"
                     v-bind:class="{'active': current_user && current_user.sns_rank === 'rank_regular_default'}">
                  <span class="class">本科：</span>
                  <span v-if="model.rank_regular_default">¥{{ delimited(model.price_rank_regular_default) }}</span>
                  <span v-else>参加できません</span>
                </div>
                <div class="price"
                     v-bind:class="{'active': current_user && current_user.sns_rank === 'rank_preparatory'}">
                  <span class="class">予科：</span>
                  <span v-if="model.rank_preparatory">¥{{ delimited(model.price_rank_preparatory) }}</span>
                  <span v-else>参加できません</span>
                </div>
                <div class="price"
                     v-bind:class="{'active': current_user && current_user.sns_rank === 'rank_student'}">
                  <span class="class">学生科：</span>
                  <span v-if="model.rank_student">¥{{ delimited(model.price_rank_student) }}</span>
                  <span v-else>参加できません</span>
                </div>
                <div class="price"
                     v-bind:class="{'active': current_user && current_user.sns_rank === 'rank_corporation'}">
                  <span class="class">法人科：</span>
                  <span v-if="model.rank_corporation">¥{{ delimited(model.price_rank_corporation) }}</span>
                  <span v-else>参加できません</span>
                </div>
                <div class="price" v-bind:class="{'active': !current_user}">
                  <span class="class">一般：</span>
                  <span v-if="model.rank_general">¥{{ delimited(model.price_rank_general) }}</span>
                  <span v-else>参加できません</span>
                </div>
              </dd>
            </dl>
            <dl class="flex" v-if="model.online">
              <dt>価格（オンライン）</dt>
              <dd>

                <div class="price"
                     v-bind:class="{'active': current_user && current_user.sns_rank === 'rank_regular_plus'}">
                  <span class="class">本科プラス：</span>
                  <span v-if="model.rank_regular_plus">¥{{ delimited(model.price_online_rank_regular_plus) }}</span>
                  <span v-else>参加できません</span>
                </div>
                <div class="price" v-if="!isRegularDefault()"
                     v-bind:class="{'active': current_user && current_user.sns_rank === 'rank_regular'}">
                  <span class="class">本科：</span>
                  <span v-if="model.rank_regular">¥{{ delimited(model.price_online_rank_regular) }}</span>
                  <span v-else>参加できません</span>
                </div>
                <div class="price active" v-if="isRegularDefault()"
                     v-bind:class="{'active': current_user && current_user.sns_rank === 'rank_regular_default'}">
                  <span class="class">本科：</span>
                  <span v-if="model.rank_regular_default">¥{{
                      delimited(model.price_online_rank_regular_default)
                    }}</span>
                  <span v-else>参加できません</span>
                </div>
                <div class="price"
                     v-bind:class="{'active': current_user && current_user.sns_rank === 'rank_preparatory'}">
                  <span class="class">予科：</span>
                  <span v-if="model.rank_preparatory">¥{{ delimited(model.price_online_rank_preparatory) }}</span>
                  <span v-else>参加できません</span>
                </div>
                <div class="price"
                     v-bind:class="{'active': current_user && current_user.sns_rank === 'rank_student'}">
                  <span class="class">学生科：</span>
                  <span v-if="model.rank_student">¥{{ delimited(model.price_online_rank_student) }}</span>
                  <span v-else>参加できません</span>
                </div>
                <div class="price"
                     v-bind:class="{'active': current_user && current_user.sns_rank === 'rank_corporation'}">
                  <span class="class">法人科：</span>
                  <span v-if="model.rank_corporation">¥{{ delimited(model.price_rank_corporation) }}</span>
                  <span v-else>参加できません</span>
                </div>
                <div class="price" v-bind:class="{'active': !current_user}">
                  <span class="class">一般：</span>
                  <span v-if="model.rank_general">¥{{ delimited(model.price_online_rank_general) }}</span>
                  <span v-else>参加できません</span>
                </div>
              </dd>
            </dl>
            <dl class="flex" v-if="model.place_text">
              <dt>会場</dt>
              <dd v-html="model.place_text"></dd>
            </dl>
            <dl class="flex" v-if="model.note_text">
              <dt>備考</dt>
              <dd v-html="model.note_text"></dd>
            </dl>
          </div>
        </div>

        <div id="event_map" class="cts_box">
          <div v-if="model.map_tag">
            <h3 class="min_ttl">会場周辺の地図</h3>
            <div class="g_map_wrap" v-html="model.map_tag"></div>
          </div>

          <!-- 暫定 抽選研修会なら申し込み関連を出さない -->
          <template v-if="false">
            <div class="btn_wrap event_cv" v-if="!model.request_enabled">
              <p class="caution" v-if="model.rank_regular_plus_request_enabled"> 現在「本科プラス」塾生の優先申し込み期間です。<br>
                通常受付開始は下記の日時となります。 </p>
              <a href="" class="submit light disabled">申し込み受付前です</a>
              <p class="limit">受付開始日：{{ moment(model.request_start, 'YYYY/MM/DD (ddd) HH:mm') }}</p>
            </div>
            <div class="btn_wrap event_cv" v-if="model.request_enabled">
              <p v-if="!model.joined">参加のお申込みはこちらから</p>
              <p class="caution" v-if="model.rank_regular_plus_request_enabled"> 現在「本科プラス」塾生の優先申し込み期間です。</p>
              <a href="javascript:void(0)"
                 v-bind:class="{disabled: model.join_contents_close || model.close || model.joined}"
                 @click="joinClicked"
                 class="submit basic arrow">この研修会に参加する
              </a>
              <p class="limit">参加申込期限：{{ moment(model.close_date, 'YYYY/MM/DD (ddd) HH:mm') }} まで</p>
              <p class="limit" v-if="model.joined">申し込み済みです。</p>
              <p class="limit" v-else-if="model.join_contents_close || model.close">申し込み受付は終了いたしました。</p>
            </div>

          </template>


        </div>
      </div>
    </section>

    <JoinAllowGeneralModal v-bind:target_id="id" v-if="show_join_allow_general_modal"
                           v-on:cancel="show_join_allow_general_modal = false"/>
    <JoinDenyGeneralModal v-bind:target_id="id" v-if="show_join_deny_general_modal"
                          v-on:cancel="show_join_deny_general_modal = false"/>

    <JoinDenyUserlModal v-bind:model="model" v-if="show_join_deny_user_modal"
                        v-on:cancel="show_join_deny_user_modal = false"/>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import JoinAllowGeneralModal from '@/views/site/workshop/elements/_join_allow_general_modal.vue'
import JoinDenyGeneralModal from '@/views/site/workshop/elements/_join_deny_general_modal.vue'
import JoinDenyUserlModal from '@/views/site/workshop/elements/_join_deny_user_modal.vue'

export default {
  mixins: [Libraries],
  components: {
    JoinAllowGeneralModal,
    JoinDenyGeneralModal,
    JoinDenyUserlModal
  },
  data() {
    return {
      category: this.$route.params.category,
      id: this.$route.params.id,
      fetch_data: null,
      model: null,
      show_join_allow_general_modal: false,
      show_join_deny_general_modal: false,
      show_join_deny_user_modal: false,
      query: this.$route.query.query
    }
  },
  mounted() {
    this.fetchData()
  },
  watch: {
    '$route'() {
      this.category = this.$route.params.category
      this.id = this.$route.params.id
      this.fetch_data = this.model = null
      this.fetchData()
    }
  },
  methods: {
    fetchData() {
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}workshop/${this.id}.json`, {
            params: {
              draft: this.$route.query.draft,
              category: this.category
            }
          })
          .then(response => {
            this.fetch_data = response.data
            this.model = response.data.workshop
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    },
    joinClicked() {
      // クラス変更の申し込み中で変更後に開催される場合、参加不可とする
      if (this.current_user && this.current_user.change_rank_at && this.current_user.change_rank_at <= this.model.open_date) {
        alert('クラス変更の予約中のため、申し込みが出来ません。クラス変更後に申し込みいただくか、事務局にご相談ください。')
        return
      }

      if (this.model.join_rank) {
        if (this.isUserSignedIn()) { // ログイン中
          this.$router.push({name: 'WorkshopForm', params: {id: this.model.id}, query: this.$route.query})
        } else {
          this.show_join_allow_general_modal = true
        }
      } else {
        if (this.isUserSignedIn()) { // ログイン中
          this.show_join_deny_user_modal = true
        } else { // 非ログイン中
          this.show_join_deny_general_modal = true
        }
      }
    },
    // 暫定 募集研修会か
    isLottery() {
      return this.model.description.substring(0, 14) === "<!-- ${抽選} -->"
    }
  }
}
</script>

<style lang="scss">
.youtube.vimeo > div {
  padding-top: 56.25% !important;
}
</style>
